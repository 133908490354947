var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tag,{ref:"dropdown",tag:"component",class:[
    'dropdown',
    `dropdown--theme-${_vm.theme}`,
    {
      'disabled': _vm.disabled
    }
  ],on:{"click":_vm.onDropdownClick}},[_vm._t("default",null,null,{
      open: _vm.isOpen,
    }),_vm._v(" "),_c('ul',{class:[
      'dropdown__body',
      `dropdown__body--${_vm.horizontal}`,
      `dropdown__body--${_vm.vertical}`,
      {
        'dropdown__body--open': _vm.isOpen
      }
    ],on:{"click":function($event){$event.stopPropagation();return _vm.onClickListElement.apply(null, arguments)}}},[_vm._t("dropdown")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }