import { setCookie } from '@alao-frontend/utils'
import { ref } from 'vue'
import { GetOrderByOutput, PublicOspStepsOutput } from '@alao-frontend/erp-services'
import { $api } from '~/plugins/axios'
import { OrderAPIException } from '~/services/exceptions/orderAPI.exception'
import { APIException } from '~/plugins/axios/lib/exception'
const COOKIE_ORDER_NAME = 'order_id'
const COOKIE_ORDER_EXP = 180

export function useSale (serializedOfferId: string) {
  const totalSavings = ref<null | number>(null)
  const completedOrders = ref<null | number>(null)
  const order = ref<GetOrderByOutput | null>(null)
  const osp = ref<PublicOspStepsOutput | null>(null)
  const orderId = ref<string | null>(null)
  const creatOrderDirectly = async (used_promo_code?: string, partner? :string) => {
    const { order_id } = await $api.PublicSalesService.createCartDirectly({ serialized_offer_id: serializedOfferId, used_promo_code, partner_slug: partner })
    const date = new Date()
    orderId.value = order_id
    setCookie(COOKIE_ORDER_NAME, order_id, {
      expires: new Date(date.setDate(date.getDate() + COOKIE_ORDER_EXP)),
      path: '/',
    })
  }
  const getSalesStatistics = async () => {
    try {
      const { total_savings, completed_orders } = await $api.OrderService.salesStatistics()
      totalSavings.value = total_savings
      completedOrders.value = completed_orders
    } catch (error) {
      throw new OrderAPIException('Unable to fetch sales statistics', (error as APIException))
    }
  }

  const getOrderInfo = async () => {
    if (orderId.value) {
      order.value = await $api.PublicSalesService.getOrderById(orderId.value)
    }
  }
  const getOspOrderInfo = async () => {
    if (orderId.value) {
      osp.value = await $api.PublicSalesService.getOrderOSPStepsById(orderId.value)
    }
  }
  return {
    getSalesStatistics,
    creatOrderDirectly,
    totalSavings,
    completedOrders,
    getOrderInfo,
    getOspOrderInfo,
    orderId
  }
}
