
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconSortBy from '~/components/icons/IconSortBy.vue'
import type { Theme } from '~/types/theme'

@Component({
  components: { IconSortBy },
})
export default class UiDropdownHandle extends Vue {
  @Prop({ required: false, default: 'button', type: String })
  readonly tag!: string

  @Prop({ required: false, default: false, type: Boolean })
  readonly borderless!: boolean

  @Prop({
    type: String,
    required: false,
    default: 'light',
  })
  readonly theme!: Theme
}
