import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Route } from 'vue-router/types/router'
import { RootState } from '~/store'
import {
  AnswerID,
  NextQuestionID,
  QuestionID,
  QuizAnswer,
  QuizQuestion,
} from '~/core/config/comparator/quiz/interfaces/types'
import { questions } from '~/core/config/comparator/quiz/questions'
import { QuizAction } from '~/core/config/comparator/quiz/interfaces/enums'

import {
  ActionType as mobileFiltersActionType,
  MutationType as mobileFiltersMutationType,
  namespace as mobileFiltersModuleNamespace,
} from '~/store/modules/Filters/Mobile'
import {
  ActionType as AmpMobileFiltersActionType,
  MutationType as AmpMobileFiltersMutationType,
  namespace as AmpMobileFiltersModuleNamespace,
} from '~/store/modules/Amp/Filters/Mobile'
import {
  ActionType as homeFiltersActionType,
  MutationType as homeFiltersMutationType,
  namespace as homeFiltersModuleNamespace,
} from '~/store/modules/Filters/Home'
import {
  ActionType as AmpHomeFiltersActionType,
  MutationType as AmpHomeFiltersMutationType,
  namespace as AmpHomeFiltersModuleNamespace,
} from '~/store/modules/Amp/Filters/Home'
import { quizAnswerToFilter } from '~/utils/filters-driver.utils'
import { FeatureFlags } from '~/core/config/feature-flags'

export enum QuizActionType {
  VIEW = 'view',
  CHOOSE = 'choose',
  COMPLETE = 'complete',
}

const QUESTION_QUERY_PARAM_KEY = 'qq' as const
const ANSWER_QUERY_PARAM_KEY = 'qa' as const
export const ADVICE_CODE_QUERY_PARAM_KEY = 'advice-code' as const

export const namespace = 'modules/Quiz'

export type QuizCompletedQuestion = {
  questionId: QuestionID,
  answerId: number,
  nextQuestionId: NextQuestionID,
}

export interface QuizState {
  questions: QuizQuestion[],
  completedQuestionsStack: QuizCompletedQuestion[],
  currentQuestionId: QuestionID | null,
}

export interface QueryParams {
  [QUESTION_QUERY_PARAM_KEY]: string[],
  [ANSWER_QUERY_PARAM_KEY]: string[],
}

export enum ActionType {
  INIT = 'init',
  COMPLETE_QUESTION = 'completeQuestion',
  APPLY_QUIZ_RESULTS = 'finishQuiz',
  UPDATE_CURRENT_QUESTION = 'updateCurrentQuestion',
  FORMAT_QUERY = 'formatQuery',
  UPDATE_ROUTE = 'updateRoute',
  RESET = 'resetQuiz',
  HANDLE_ANALYTICS = 'handleAnalytics',
}

export interface CompletedQuestionAnalyticsPayload {
  step: number,
  type: QuizActionType.CHOOSE | QuizActionType.COMPLETE,
  answer: QuizCompletedQuestion,
}

export interface OngoingQuestionAnalyticsPayload {
  step: number,
  type: QuizActionType.VIEW,
}

export type HandleAnalyticsPayload = CompletedQuestionAnalyticsPayload | OngoingQuestionAnalyticsPayload

export interface PublicAction {
  [ActionType.INIT](): Promise<void>,

  [ActionType.COMPLETE_QUESTION](payload: QuizCompletedQuestion): void,

  [ActionType.APPLY_QUIZ_RESULTS](payload: QuizCompletedQuestion): Promise<void>,

  [ActionType.HANDLE_ANALYTICS](payload: HandleAnalyticsPayload): void,

  [ActionType.FORMAT_QUERY](): Promise<QueryParams>,

  [ActionType.UPDATE_ROUTE](query: Route['query']): void,

  [ActionType.RESET](): void,
}

export enum MutationType {
  PUSH_STACK = 'pushStack',
  POP_STACK = 'popStack',
  EMPTY_STACK = 'emptyStack',
  SET_CURRENT_QUESTION = 'setCurrentQuestion',
}

export enum GetterType {
  ADVICE_CODE = 'adviceCode',
  ANSWER_ROUTE_ACTION = 'answerRouteAction',
}

export interface PublicGetter {
  [GetterType.ADVICE_CODE]: string,
  [GetterType.ANSWER_ROUTE_ACTION]: QuizAnswer['action'] | undefined,
}

const getQueryParamAsArray = (
  query: Route['query'],
  key: string,
): string[] => {
  const param = query[key]

  if (!param) {
    return []
  }

  if (typeof param === 'string') {
    return [param]
  }

  return param as string[]
}

const initialState = (): QuizState => ({
  questions,
  completedQuestionsStack: [],
  currentQuestionId: null,
})

export const state = (): QuizState => initialState()

export const getters: GetterTree<QuizState, RootState> = {
  getCompletedQuestionsStack: ({ completedQuestionsStack }) => completedQuestionsStack,

  getQuestionById: ({ questions }) =>
    (id: QuestionID): QuizQuestion | void =>
      questions.find(q => q.id === id),

  getAnswerById: ({ questions }) =>
    (id: AnswerID): QuizAnswer | null => {
      let answer: QuizAnswer | null = null
      questions.forEach((e) => {
        answer = e.answers.find(a => a.id === id) || answer
      })
      return answer
    },

  getCurrentQuestion: ({ currentQuestionId }, getters): QuizQuestion =>
    getters.getQuestionById(currentQuestionId) as QuizQuestion,

  // At least one question must be root
  getRootQuestion: ({ questions }): QuizQuestion => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return questions.find(item => item.root)!
  },

  getCurrentQuestionNumber: ({ completedQuestionsStack }) => {
    const length = completedQuestionsStack.length

    if (length === 0) {
      return 1
    }

    if (completedQuestionsStack[0].nextQuestionId) {
      return length + 1
    }

    return length
  },

  getMaxQuestionsCount: (state, getters) => {
    const rootElement = getters.getRootQuestion

    // TEMP SOLUTION
    // @TODO: Get rid of it, recalculate it on every step change
    if (state.completedQuestionsStack.length === 0 || state.completedQuestionsStack[state.completedQuestionsStack.length - 1].answerId !== 3) {
      return 3
    }

    const getMaxDepth = ({ answers = [] }: QuizQuestion, count = 0): number => {
      if (answers.length) {
        count += Math.max(0, ...answers.map(({ nextQuestionId }) => {
          const _count = 1

          if (nextQuestionId !== null) {
            const next = getters.getQuestionById(nextQuestionId)

            if (next) {
              return getMaxDepth(next, _count)
            }
          }

          return _count
        }))
      }

      return count
    }

    return getMaxDepth(rootElement)
  },

  getStateFromQuery: (_, getters) => (query: Route['query']) => {
    try {
      const questionIDs = getQueryParamAsArray(query, QUESTION_QUERY_PARAM_KEY).map(Number)
      const answerIDs = getQueryParamAsArray(query, ANSWER_QUERY_PARAM_KEY).map(Number)

      const answers: QuizCompletedQuestion[] = []

      questionIDs.forEach((questionId, index) => {
        const answerId = answerIDs[index]
        const question = getters.getQuestionById(questionId as QuestionID) as QuizQuestion
        const answer = question.answers.find(answer => answer.id === answerId)

        if (!question || !answer) {
          throw new Error('Unable to match question or answer')
        }

        answers.push({
          questionId,
          answerId,
          nextQuestionId: answer.nextQuestionId,
        })
      })

      return answers
    } catch (e) {
      return []
    }
  },

  [GetterType.ADVICE_CODE]: (state, getters): PublicGetter[GetterType.ADVICE_CODE] => {
    const adviceCodeParts: QuizAnswer['adviceCodePart'][] = []
    state.completedQuestionsStack.slice().reverse()
      .forEach(({ answerId }) => {
        const answer = getters.getAnswerById(answerId) as QuizAnswer | null
        const codePart = answer?.adviceCodePart
        if (codePart) {
          adviceCodeParts.push(codePart)
        }
      })

    return adviceCodeParts.join('')
  },

  answers: (state, getters) => state.completedQuestionsStack.map(e => getters.getAnswerById(e.answerId)),

  [GetterType.ANSWER_ROUTE_ACTION]: (state, getters) => {
    return (getters.answers as QuizAnswer[]).find(a => a.action.type === QuizAction.ROUTE)?.action
  },
}

export const actions: ActionTree<QuizState, RootState> = {
  [ActionType.INIT] (
    { commit, getters, dispatch },
  ): ReturnType<PublicAction[ActionType.INIT]> {
    return new Promise((resolve) => {
      const { query } = this.$router.currentRoute

      commit(MutationType.EMPTY_STACK)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const stack = getters.getStateFromQuery(query) as QuizCompletedQuestion[]

      if (stack.length) {
        stack.forEach((item, index) => {
          commit(MutationType.PUSH_STACK, item)

          if (index === stack.length - 1) {
            commit(MutationType.SET_CURRENT_QUESTION, item.nextQuestionId)
          }
        })
      } else {
        // Use root question instead
        const root = getters.getRootQuestion

        commit(MutationType.SET_CURRENT_QUESTION, root.id)
      }

      dispatch(ActionType.HANDLE_ANALYTICS, {
        step: getters.getCurrentQuestionNumber as number,
        type: QuizActionType.VIEW,
      })

      resolve()
    })
  },

  [ActionType.COMPLETE_QUESTION] (
    { commit, dispatch, getters },
    payload: Parameters<PublicAction[ActionType.COMPLETE_QUESTION]>[0],
  ): ReturnType<PublicAction[ActionType.COMPLETE_QUESTION]> {
    const currentStep = getters.getCurrentQuestionNumber as number

    dispatch(ActionType.HANDLE_ANALYTICS, {
      step: currentStep,
      type: QuizActionType.CHOOSE,
      answer: payload,
    })

    commit(MutationType.PUSH_STACK, payload)

    if (payload.nextQuestionId) {
      dispatch(ActionType.FORMAT_QUERY).then((query) => {
        dispatch(ActionType.UPDATE_ROUTE, query)
      })
    } else {
      dispatch(ActionType.HANDLE_ANALYTICS, {
        step: currentStep,
        type: QuizActionType.COMPLETE,
        answer: payload,
      })

      dispatch(ActionType.APPLY_QUIZ_RESULTS, payload)
    }
  },

  async [ActionType.APPLY_QUIZ_RESULTS] (
    { commit, dispatch, getters },
    payload: Parameters<PublicAction[ActionType.APPLY_QUIZ_RESULTS]>[0],
  ) {
    const answerActions = (getters.answers as QuizAnswer[]).map(el => el.action)
    const answerRoute: QuizAnswer['action'] | undefined = getters.answerRouteAction

    const namespaceMobile = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpMobileFiltersModuleNamespace : mobileFiltersModuleNamespace
    const actionMobile = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpMobileFiltersActionType : mobileFiltersActionType
    const mutationMobile = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpMobileFiltersMutationType : mobileFiltersMutationType

    const namespaceHome = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpHomeFiltersModuleNamespace : homeFiltersModuleNamespace
    const actionHome = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpHomeFiltersActionType : homeFiltersActionType
    const mutationHome = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR) ? AmpHomeFiltersMutationType : homeFiltersMutationType

    switch (answerRoute?.name) {
      case 'compare-mobile':
        dispatch(`${namespaceMobile}/${actionMobile.RESET_FILTER}`, null, { root: true })
        break
      case 'compare-home':
        dispatch(`${namespaceHome}/${actionHome.RESET_FILTER}`, null, { root: true })
        break
      case 'compare-bundle':
        dispatch(`${namespaceMobile}/${actionMobile.RESET_FILTER}`, null, { root: true })
        dispatch(`${namespaceHome}/${actionHome.RESET_FILTER}`, null, { root: true })
        break
    }

    const filters = quizAnswerToFilter(answerActions, this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR))

    filters.mobile.forEach((a) => {
      commit(`${namespaceMobile}/${mutationMobile.SET_FILTER}`, {
        key: a.name,
        value: a.payload,
      }, { root: true })
    })
    filters.home.forEach((a) => {
      commit(`${namespaceHome}/${mutationHome.SET_FILTER}`, {
        key: a.name,
        value: a.payload,
      }, { root: true })
    })

    const currentAnswer = getters.getAnswerById(payload.answerId)
    if (currentAnswer.action.name === 'get-advice') {
      await dispatch(ActionType.FORMAT_QUERY).then((query) => {
        this.$router.push(this.localePath({
          name: 'get-advice',
          query,
        }))
      })
      return
    }

    this.$router.push(this.localePath({
      name: answerRoute?.name ?? 'index',
    }))
  },

  [ActionType.HANDLE_ANALYTICS] (
    { getters },
    payload: Parameters<PublicAction[ActionType.HANDLE_ANALYTICS]>[0],
  ): ReturnType<PublicAction[ActionType.HANDLE_ANALYTICS]> {
    const lang = this.app.i18n.locale

    const stack = getters.getCompletedQuestionsStack
    let category = ''

    if (stack.length > 0) {
      const firstAnswerId = stack[stack.length - 1].answerId
      const firstAnswer = getters.getAnswerById(firstAnswerId)
      category = this.app.i18n.t(firstAnswer.title, 'en') as string
    }

    if (process.client) {
      if (payload.type === QuizActionType.VIEW) {
        this.$analytics.GTM.trackRaw({
          eventCategory: 'quiz',
          eventAction: [`step_${payload.step}`, category].join('|'),
          eventLabel: QuizActionType.VIEW,
          language_code: this.$i18n.locale.toUpperCase(),
        })
      } else if (payload.type === QuizActionType.CHOOSE) {
        const answerObject = getters.getAnswerById(payload.answer.answerId)
        const title = this.app.i18n.t(answerObject.title, 'en') as string

        this.$analytics.GTM.trackRaw({
          eventCategory: 'quiz',
          eventAction: [`step_${payload.step}`, category].join('|'),
          eventLabel: [QuizActionType.CHOOSE, title].join('|'),
          language_code: this.$i18n.locale.toUpperCase(),
        })
      } else if (payload.type === QuizActionType.COMPLETE) {
        this.$analytics.GTM.trackRaw({
          eventCategory: 'quiz',
          eventAction: `complete_${payload.step}`,
          eventLabel: '',
          language_code: this.$i18n.locale.toUpperCase(),
        })
      }
    }
  },

  [ActionType.FORMAT_QUERY] (
    ctx,
  ): ReturnType<PublicAction[ActionType.FORMAT_QUERY]> {
    return new Promise((resolve, reject) => {
      const { getters } = ctx
      const completedQuestionsStack = getters.getCompletedQuestionsStack as QuizCompletedQuestion[]

      try {
        const initialPayload: QueryParams = {
          [QUESTION_QUERY_PARAM_KEY]: [],
          [ANSWER_QUERY_PARAM_KEY]: [],
        }
        const payload = completedQuestionsStack.reduceRight((
          acc,
          {
            questionId,
            answerId,
          },
        ) => {
          acc[QUESTION_QUERY_PARAM_KEY].push(questionId.toString())
          acc[ANSWER_QUERY_PARAM_KEY].push(answerId.toString())
          return acc
        }, initialPayload)

        return resolve(payload)
      } catch (e) {
        reject(e)
      }
    })
  },

  [ActionType.UPDATE_ROUTE] (
    _, query: Parameters<PublicAction[ActionType.UPDATE_ROUTE]>[0],
  ): ReturnType<PublicAction[ActionType.UPDATE_ROUTE]> {
    this.$router.push(this.localePath({
      name: 'quiz',
      query,
    }))
  },

  [ActionType.RESET] (
    ctx,
  ): ReturnType<PublicAction[ActionType.RESET]> {
    ctx.dispatch(ActionType.INIT)
  },
}

export const mutations: MutationTree<QuizState> = {
  [MutationType.PUSH_STACK] (state, payload: QuizCompletedQuestion): void {
    state.completedQuestionsStack.unshift(payload)
  },

  [MutationType.POP_STACK] (state): void {
    state.completedQuestionsStack.pop()
  },

  [MutationType.EMPTY_STACK] (state): void {
    state.completedQuestionsStack = []
  },

  [MutationType.SET_CURRENT_QUESTION] (state, id: QuestionID) {
    state.currentQuestionId = id
  },
}
