
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue'

import { useUserId } from '~/composables/user-id.composable'

import { FeatureFlags } from '~/core/config/feature-flags'
import SeoLayoutMixin from '~/layouts/mixins/seo'
import CookieBanner from '~/components/CookieBanner.vue'
import ExitPopupWithVoucher from '~/components/ExitPopupWithVoucher/ExitPopupWithVoucher.vue'
import AppLayoutOverlay from '~/components/AppLayoutOverlay/AppLayoutOverlay.vue'
import AppHubspotChat from '~/components/AppHubspotChat.vue'
import AppDixaChat from '~/components/AppDixaChat.vue'
import DealOfTheDay from '~/components/DealOfTheDay/DealOfTheDay.vue'
import AlaoNotification from '~/components/AlaoNotification/AlaoNotification.vue'
import BenefitsFortuneWheelModal from '~/components/BenefitsFortuneWheelModal/BenefitsFortuneWheelModal.vue'
import OnMouseLeaveDisplay from '~/components/OnMouseLeaveDisplay/OnMouseLeaveDisplay.vue'
import OnIdleTimeoutDisplay from '~/components/OnIdleTimeoutDisplay/OnIdleTimeoutDisplay.vue'
import AppAssistant from '~/components/AppAssistant/AppAssistant.vue'
import AppWAWidget from '~/components/AppWAWidget.vue'

// A list of routes where some components could be displayed
const allowedRoutesList = [
  'index',
  'compare-mobile',
  'compare-home',
  'compare-bundle',
  'blogs',
  'blogs-slug',
  'provider-slug',
  'black-friday',
  'black-friday-slug',
  'black-friday-mobile-plans',
  'black-friday-internet-plans',
  'about-us',
  'how-it-works',
  'coverage',
  'ratings',
]

export default defineComponent({
  name: 'AppLayout',

  components: {
    OnIdleTimeoutDisplay,
    OnMouseLeaveDisplay,
    BenefitsFortuneWheelModal,
    DealOfTheDay,
    AppHubspotChat,
    AppDixaChat,
    AppLayoutOverlay,
    CookieBanner,
    ExitPopupWithVoucher,
    AlaoNotification,
    AppAssistant,
    AppWAWidget,
  },

  mixins: [SeoLayoutMixin],

  setup () {
    const isFortuneWheelVisible = ref(false)
    const app = getCurrentInstance()

    const { userId } = useUserId()

    onMounted(() => {
      app?.proxy.$growthbook.initialize({
        userId: userId.value,
        trackingCallback: (experiment, result) => {
          app?.proxy.$analytics.GTM.sendGrowthbookCallbackEvent({
            experimentKey: experiment.key,
            variationId: result.key,
          })
        },
      })
    })

    return {
      isFortuneWheelVisible,
    }
  },

  data () {
    return {
      featureHubspotChat: this.$featureFlags.isEnabled(FeatureFlags.HUBSPOT_CHAT),
      featureDixaChat: this.$featureFlags.isEnabled(FeatureFlags.DIXA_CHAT),
      featureDealsOfTheDayWidget: this.$featureFlags.isEnabled(FeatureFlags.DEALS_OF_THE_DAY_WIDGET),
      featureStaticExitIntentPopup: this.$featureFlags.isEnabled(FeatureFlags.STATIC_EXIT_INTENT_POPUP),
      waWidgetEnabled: this.$config.WA_WIDGET_ENABLED,
      waWidgetNumber: this.$config.WA_WIDGET_NUMBER,
    }
  },

  computed: {
    isAllowedRoute () {
      // Route name consists of two parts: the name itself and language code (e.g. 'index___en')
      const separator = '___'

      return allowedRoutesList.includes((this.$route.name || '').split(separator)[0])
    },

    isOpenChatImmediately () {
      return Boolean(this.$route.query.open_chat) || false
    },

    isShowExitPopupWithVoucher () {
      return this.$mqUtils.isDesktop && this.featureStaticExitIntentPopup && this.isAllowedRoute
    },

    isDisplayFortuneWheel () {
      return this.isAllowedRoute
    },

    isShowDealOfTheDay () {
      return this.featureDealsOfTheDayWidget && this.isAllowedRoute
    },
  },
})
