
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import VueI18n from 'vue-i18n'
import UiDropdown from '~/components/ui/UIDropdown/UiDropdown.vue'
import UiDropdownItem from '~/components/ui/UIDropdown/UiDropdownItem.vue'
import UiDropdownHandle from '~/components/ui/UIDropdown/UiDropdownHandle.vue'
import { FeatureFlags } from '~/core/config/feature-flags'
import { CUSTOM_HEADER } from '~/core/config/headers'
import type { Theme } from '~/types/theme'

enum Locales {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
}

@Component({
  components: {
    UiDropdownHandle,
    UiDropdownItem,
    UiDropdown,
  },
})
export default class AppLangSwitcher extends Vue {
  @Prop({
    type: String,
    required: false,
    default: 'light',
  })
  readonly theme!: Theme

  get isLangSwitcherDisabled () {
    const isb2bRoute = this.localeRoute('b2b')?.name === this.$route.name
    const isOrderRoute = this.localePath('order') === this.$route.path || this.localeRoute('order-slug')?.name === this.$route.name
    const isContinueOrderRoute = this.localeRoute('amp-slug')?.name === this.$route.name
    return isb2bRoute || isOrderRoute || isContinueOrderRoute
  }

  get langList () {
    return Object.values(Locales).filter(code => code.toString() !== this.currentLang.toString())
  }

  get currentLang (): VueI18n.Locale {
    return this.$i18n.locale
  }

  setLang (localeCode: VueI18n.Locale): void {
    if (this.$featureFlags.isEnabled(FeatureFlags.AMP_BASE_INFO)) {
      this.$axios.setHeader(CUSTOM_HEADER.LANG, localeCode)
    }
    this.$router.replace(this.switchLocalePath(localeCode))
  }
}
