var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'nuxt-link' : 'button',_vm._g(_vm._b({tag:"component",class:[
    _vm.$style['button'],
    _vm.$style[`button--${_vm.theme}`],
    _vm.$style[`button--${_vm.size}`],
    {
      [_vm.$style['button--arrow']]: _vm.arrow,
      [_vm.$style['button--circle']]: _vm.circle,
      [_vm.$style['button--ghost']]: _vm.ghost,
    }
  ]},'component',_vm.to ? { to: _vm.to } : _vm.$attrs,false),_vm.$listeners),[_vm._t("default"),_vm._v(" "),(_vm.arrow)?_c('span',{class:_vm.$style['button__arrow']},[_c('ButtonArrow')],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }