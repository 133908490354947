
import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator'
import { GRWidgetState, namespace as grWidgetStore } from '~/store/modules/GRWidget'
import IconGoogle from '~/components/icons/IconGoogle.vue'

import type { Theme } from '~/types/theme'

const GRWidgetStore = namespace(grWidgetStore)

@Component({
  components: {
    IconGoogle,
  },
})
export default class GoogleRatingLite extends Vue {
  @GRWidgetStore.State('isLoading')
  isLoading!: GRWidgetState['isLoading']

  @GRWidgetStore.State('isLoaded')
  isLoaded!: GRWidgetState['isLoaded']

  @GRWidgetStore.State('rating')
  rating!: GRWidgetState['rating']

  @GRWidgetStore.State('reviews')
  reviews!: GRWidgetState['reviews']

  @Prop({ default: 'md', type: String })
  readonly size!: 'md' | 'lg'

  @Prop({ default: 'light', type: String })
  readonly theme!: Theme

  get iconSize (): number {
    switch (this.size) {
      case 'md':
        return this.$mqUtils.isMobile ? 18 : 25

      case 'lg':
        return 34
    }
  }

  get ratingRounded (): number {
    return Math.ceil(this.rating)
  }
}
