var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tag,{tag:"component",class:[
    'dropdown-handle',
    `dropdown-handle--theme-${_vm.theme}`,
    {
      'dropdown-handle--borderless': _vm.borderless,
    }
  ],attrs:{"type":"button","data-toggle":"dropdown"}},[(_vm.$slots['icon-prepend'])?_c('span',{staticClass:"dropdown-handle__icon-prepend"},[_vm._t("icon-prepend")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"dropdown-handle__content"},[_vm._t("default")],2),_vm._v(" "),_c('svg',{staticClass:"dropdown-handle__icon-arrow",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8 10L12 14L16 10","stroke":_vm.theme === 'light' ? '#444444' : '#ffffff',"stroke-width":"2","stroke-linecap":"round"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }