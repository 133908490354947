
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import type { Theme } from '~/types/theme'

@Component({
  inheritAttrs: false,
})
export default class UiDropdownItem extends Vue {
  @Prop({
    type: String,
    required: false,
    default: 'light',
  })
  readonly theme!: Theme

  @Prop({ required: false, default: false, type: Boolean })
  readonly active!: boolean

  @Prop({ required: false, default: 'a', type: String })
  readonly tag!: string
}
