// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AssistantContext } from '~alao-assistant/class'

export default function (_ctx, inject) {
  /**
   * @type {ModuleOptions} options - Nuxt module options
   */
  const options = JSON.parse('{"iframeOrigin":"https://adviser.alao.ch","fallbackLocale":"en","postMessageNamespace":"alao-assistant","theme":"light","debug":false,"disabled":false,"displayFeedbackMessagesThreshold":3}') || {}

  const assistantContext = new AssistantContext(options)

  inject('assistant', assistantContext)
}
