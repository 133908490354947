
import { computed, defineComponent, getCurrentInstance, PropType, ref } from 'vue'

import { AssistantCompact, AssistantFullscreen } from '~/components/AppAssistant/variants'
import AssistantContainer from '~/components/AppAssistant/AssistantContainer.vue'
import AssistantFeedbackModal from '~/components/AppAssistant/feeback/AssistantFeedbackModal.vue'
import { AssistantOfferContext } from '~/modules/assistant-v2'
import AppModal from '~/components/AppModal/AppModal.vue'

export default defineComponent({
  name: 'AppAssistant',
  components: {
    AppModal,
    AssistantCompact,
    AssistantFullscreen,
    AssistantContainer,
    AssistantFeedbackModal,
  },
  props: {
    variant: {
      type: String as PropType<'compact' | 'fullscreen'>,
      default: 'compact',
    },
  },
  setup (props) {
    const isOpen = ref(false)
    const isReady = ref(false)
    const isError = ref(false)
    const isFeedbackVisible = ref(false)
    const chatId = ref('')
    const offerContext = ref<AssistantOfferContext | undefined>()

    const app = getCurrentInstance()

    const assistantComponent = computed(() => {
      switch (props.variant) {
        case 'fullscreen':
          return AssistantFullscreen
        default:
          return AssistantCompact
      }
    })

    const onReady = () => {
      isReady.value = true
      isError.value = false
    }

    const onError = () => {
      isReady.value = false
      isError.value = true
    }

    const setOfferContext = (newOfferContext: AssistantOfferContext | undefined) => {
      offerContext.value = newOfferContext
    }

    const setOpenState = () => {
      isOpen.value = true

      app?.proxy.$analytics.GTM.sendAssistantClickOpenEvent(
        offerContext.value as AssistantOfferContext,
      )
    }

    const setCloseState = () => {
      isOpen.value = false

      app?.proxy.$analytics.GTM.sendAssistantClickCloseEvent(
        offerContext.value as AssistantOfferContext,
      )
    }

    const onRequestFeedback = (id: string) => {
      isFeedbackVisible.value = true
      chatId.value = id
    }

    const onMessageSend = (message: string) => {
      app?.proxy.$analytics.GTM.sendAssistantUserMessageEvent(
        Object.assign({},
          offerContext.value as AssistantOfferContext,
          {
            message,
          }),
      )
    }

    const onTabClick = (tabName: string) => {
      app?.proxy.$analytics.GTM.sendAssistantHintClickEvent(
        Object.assign({},
          offerContext.value as AssistantOfferContext,
          {
            tabName,
          }),
      )
    }

    const onOrderButtonClick = () => {
      app?.proxy.$analytics.GTM.sendAssistantBeginCheckoutEvent(
        offerContext.value as AssistantOfferContext,
      )
    }

    return {
      assistantComponent,
      isOpen,
      isReady,
      isError,
      isFeedbackVisible,
      offerContext,
      chatId,
      setOpenState,
      setCloseState,
      onRequestFeedback,
      onReady,
      onError,
      setOfferContext,
      onMessageSend,
      onTabClick,
      onOrderButtonClick,
    }
  },
  watch: {
    $i18n: {
      handler () {
        this.$assistant.setLocale(this.$i18n.locale)
      },
      deep: true,
    },
  },

  mounted () {
    try {
      const containerEl = (this.$refs.containerRef as InstanceType<typeof AssistantContainer>).$el as HTMLDivElement

      if (!containerEl) {
        throw new Error('Assistant container element not found')
      }

      this.$assistant.on('open', this.setOpenState.bind(this))
        .on('close', this.setCloseState.bind(this))
        .on('requestFeedback', this.onRequestFeedback.bind(this))
        .on('ready', this.onReady.bind(this))
        .on('error', this.onError.bind(this))
        .on('offerContextUpdate', this.setOfferContext.bind(this))
        .on('message', this.onMessageSend.bind(this))
        .on('tabClick', this.onTabClick.bind(this))

      this.$assistant.mount(containerEl, this.$i18n.locale)
    } catch (e) {
      this.isError = true
    }
  },

  beforeDestroy () {
    this.$assistant.destroy()
    this.setOfferContext(undefined)
  },
})
