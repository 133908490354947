import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F8a4d988b04254294a26bc289d6794d8a@o183814.ingest.sentry.io\u002F4504756283637760",
    environment:"production",
    release:"02ec0f619de2bd822ff259ebdb79f4af1ebd96e4",
    tracesSampleRate:0.2,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.2,"browserTracing":{},"vueOptions":{"trackComponents":true,"hooks":["mount","update"],"timeout":20000},"vueRouterInstrumentationOptions":{"routeLabel":"name"}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
