
import { Component, namespace } from 'nuxt-property-decorator'
import GoogleRating from '~/components/widgets/GoogleRating.vue'
import PhoneNumberLink from '~/components/snippets/PhoneNumberLink.vue'
import { namespace as offerStore, OfferState } from '~/store/modules/Offer'
import { SiteNavigationMixin } from '~/mixins/site-navigation.mixin'
import IconWA from '~/assets/icons/icon-wa.svg?inline'

const OfferStore = namespace(offerStore)

@Component({
  components: {
    GoogleRating,
    PhoneNumberLink,
    IconWA,
  },
})
export default class AppFooter extends SiteNavigationMixin {
  readonly currentYear = (new Date()).getFullYear()
  readonly waContactNumber = this.$config.WA_WIDGET_NUMBER

  @OfferStore.Getter('partnersList') partnersList!: OfferState['providersList']
}
