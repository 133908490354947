// TODO: Move to @alao-frontend/utils
export const bodyScroll = {
  lock () {
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
    document.body.style.overflow = 'hidden'
  },

  unlock () {
    const scrollY = parseInt(document.body.style.top) || 0
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.overflow = ''
    window.scrollTo(0, scrollY * -1)
  },
}
