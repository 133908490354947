var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{class:[
    'dropdown-item',
    `dropdown-item--theme-${_vm.theme}`,
  ]},[_c(_vm.tag,_vm._g(_vm._b({tag:"component",class:[
      'dropdown-item__handle',
      {
        'dropdown-item__handle--active': _vm.active,
      }
    ]},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }