

import { Component, PropSync, Ref, Vue, Watch } from 'nuxt-property-decorator'
import { getCookie, setCookie } from '@alao-frontend/utils'
import AppFortuneWheel from '~/components/AppFortuneWheel/AppFortuneWheel.vue'
import AppModal from '~/components/AppModal/AppModal.vue'
import AppTransition from '~/components/AppTransition/AppTransition.vue'
import FullPageConfetti from '~/components/promos/giveaway/FullPageConfetti.vue'
import { $api } from '~/plugins/axios'
import { WheelOfFortuneBonus } from '~/types/wheel-of-fortune.types'
import { OnCreated } from '~/core/vue.types'
import { FeatureFlags } from '~/core/config/feature-flags'

enum Screen {
  Wheel = 'wheel',
  Result = 'result',
}

const CONFETTI_ITEMS = 500
const CONFETTI_STAGES = [
  {
    ratio: 0.25,
    options: {
      spread: 26,
      startVelocity: 55,
    },
  },
  {
    ratio: 0.2,
    options: {
      spread: 60,
    },
  },
  {
    ratio: 0.35,
    options: {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    },
  },
  {
    ratio: 0.1,
    options: {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    },
  },
  {
    ratio: 0.1,
    options: {
      spread: 120,
      startVelocity: 45,
    },
  },
]

@Component({
  components: {
    FullPageConfetti,
    AppTransition,
    AppModal,
    AppFortuneWheel,
  },
})
export default class BenefitsFortuneWheelModal extends Vue implements OnCreated {
  @PropSync('visible', { type: Boolean, default: false })
  isVisible!: boolean

  @Ref('confetti')
  readonly confetti!: FullPageConfetti

  winnerIndex: number | null = null

  benefits: WheelOfFortuneBonus[] = []
  screen: Screen = Screen.Wheel

  get winner (): WheelOfFortuneBonus | null {
    if (this.winnerIndex === null) {
      return null
    }
    return this.benefits[this.winnerIndex]
  }

  get isShow () {
    return !getCookie('isFortuneWheelModalHidden') && this.isVisible && this.benefits.length > 0
  }

  get benefitLabels () {
    return this.benefits.map(item => item.title)
  }

  storeSiteLevelPromoCode (promoCode: string) {
    const {
      SLPC_COOKIE_NAME: cookieName,
      SLPC_COOKIE_EXP: expiration,
      APP_DOMAIN: domain,
    } = this.$config

    setCookie(cookieName, promoCode, {
      expires: expiration,
      domain,
      path: '/',
    })
  }

  setWinner (index: number) {
    this.winnerIndex = index

    if (this.winner) {
      this.storeHiddenState()
      this.storeSiteLevelPromoCode(this.winner.code)

      const {
        title,
        code,
      } = this.winner

      this.sendGTMEvent('wheel_of_fortune_bonus_shown', {
        event_action: 'bonus_shown',
        event_label: `${title}|${code}`,
      })
    }
  }

  onSpinStart () {
    this.sendGTMEvent('wheel_of_fortune_spin_click', {
      event_action: 'spin_click',
      event_label: '',
    })
  }

  onSpinEnd (index: number) {
    this.setWinner(index)

    setTimeout(() => {
      this.screen = Screen.Result

      this.fireConfetti()
    }, 1000)
  }

  fireConfetti () {
    setTimeout(() => {
      for (const step of CONFETTI_STAGES) {
        this.confetti.fire({
          ...step.options,
          particleCount: Math.floor(step.ratio * CONFETTI_ITEMS),
          origin: {
            y: 0.7,
          },
        })
      }
    }, 200)
  }

  sendGTMEvent <T extends Record<string, unknown>>(
    eventName: |
      'wheel_of_fortune_shown' |
      'wheel_of_fortune_spin_click' |
      'wheel_of_fortune_bonus_shown' |
      'wheel_of_fortune_close_click' |
      'wheel_of_fortune_cta_click',
    payload: T,
  ) {
    const data = Object.assign({}, {
      event_category: 'wheel_of_fortune', // values are shown just for example
      language_code: this.$i18n.locale.toUpperCase(),
    }, payload)

    this.$analytics.GTM.trackRaw({
      event: 'GTMevent-v2',
      eventName,
      data,
      _clear: true,
    })
  }

  async fetchBenefits () {
    try {
      if (this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)) {
        this.benefits = (await $api.PublicHandbookService.searchWheelOfFortuneBonuses()).items.map(item => ({
          title: item.profit_title,
          value: Number(item.profit_value),
          code: item.promocode,
          type: item.bonus_type,
        }))
      } else {
        this.benefits = await $api.CommonService.getWheelOfFortuneBonuses(this.$i18n.locale)
      }
    } catch (e) {
    }
  }

  onShown () {
    this.sendGTMEvent('wheel_of_fortune_shown', {
      event_action: 'shown',
      event_label: '',
    })
  }

  onCTAClick () {
    // Make sure the winner is set
    if (this.winner) {
      const {
        title,
        code,
      } = this.winner

      this.sendGTMEvent('wheel_of_fortune_cta_click', {
        event_action: 'cta_click',
        event_label: `${title}|${code}`,
      })
    }

    this.modalClose()
  }

  onModalClose () {
    this.sendGTMEvent('wheel_of_fortune_close_click', {
      event_action: 'close_click',
      event_label: '',
    })

    this.modalClose()
  }

  storeHiddenState () {
    const date = new Date()

    setCookie('isFortuneWheelModalHidden', 1, {
      expires: new Date(date.setDate(date.getDate() + 2)),
      path: '/',
    })
  }

  modalClose () {
    this.isVisible = false

    this.storeHiddenState()
  }

  created () {
    this.fetchBenefits()
  }

  @Watch('isShow', {
    immediate: true,
  })
  onIsShowStateChange (isShow: boolean) {
    if (isShow) {
      this.onShown()
    }
  }
}
