import type { GetterTree, ActionTree, MutationTree } from 'vuex'
import { IGoogleRating } from '~/core/offer.types'
import { RootState } from '~/store'
import { $api } from '~/plugins/axios'

export const namespace = 'modules/GRWidget'

export interface GRWidgetState {
  rating: number,
  reviews: number,
  isLoading: boolean,
  isLoaded: boolean,
}

export enum GetterType {
  RATING = 'rating',
  REVIEWS = 'reviews',
  IS_LOADING = 'isLoading',
  IS_LOADED = 'isLoaded',
}

export interface PublicGetter {
  [GetterType.RATING]: number,
  [GetterType.REVIEWS]: number,
  [GetterType.IS_LOADING]: boolean,
  [GetterType.IS_LOADED]: boolean,
}

export const MutationType = {
  SET_IS_LOADING: 'setIsLoading',
  SET_IS_LOADED: 'setIsLoaded',
  SET_RATING: 'setRating',
}

export const ActionType = {
  FETCH_RATING: 'fetchRating',
}

export const state = (): GRWidgetState => ({
  rating: 0,
  reviews: 0,
  isLoading: false,
  isLoaded: false,
})

export const getters: GetterTree<GRWidgetState, RootState> = {
  [GetterType.RATING] (state): number {
    return state.rating
  },
  [GetterType.REVIEWS] (state): number {
    return state.reviews
  },
  [GetterType.IS_LOADING] (state): boolean {
    return state.isLoading
  },
  [GetterType.IS_LOADED] (state): boolean {
    return state.isLoaded
  },
}

export const mutations: MutationTree<GRWidgetState> = {
  [MutationType.SET_IS_LOADING] (state, isLoading: boolean): void {
    state.isLoading = isLoading
  },

  [MutationType.SET_IS_LOADED] (state, isLoaded: boolean): void {
    state.isLoaded = isLoaded
  },

  [MutationType.SET_RATING] (state, { rating, reviews }: IGoogleRating): void {
    state.rating = rating
    state.reviews = reviews
  },
}

export const actions: ActionTree<GRWidgetState, RootState> = {
  async [ActionType.FETCH_RATING] ({ commit }) {
    commit(MutationType.SET_IS_LOADING, true)

    try {
      const results: IGoogleRating = await $api.GeocodeService.getRatingsAndReviews()

      commit(MutationType.SET_RATING, results)
      commit(MutationType.SET_IS_LOADED, true)
    } catch (e) {
      // TODO: Handle error
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      commit(MutationType.SET_IS_LOADING, false)
    }
  },
}
