
import { defineComponent, PropType } from 'vue'
import ButtonArrow from './ButtonArrow.vue'
import { Theme } from '~/types/theme'

export default defineComponent({
  name: 'AlButton',
  components: {
    ButtonArrow,
  },
  props: {
    theme: {
      type: String as PropType<Theme>,
      default: 'light',
    },
    size: {
      type: String as PropType<'xxs'| 'xs' | 'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: undefined,
      required: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
})
