import pickBy from 'lodash/pickBy'
import { ApplicationService } from '~/services/core/application.service'
import { Partner } from '~/services/partner.service'

export type FeedbackPayload = {
  rating: number,
  chat_id: string,
  comment: string,
  environment: 'production' | 'release' | 'development',
}

export type SubscriptionResponse = {
  id: string,
  email: string,
  locale: string,
  first_name: string,
  last_name: string,
  created_at: string,
}

export class LemurService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_URL
  }

  sendFeedback (feedback: FeedbackPayload, locale: string): Promise<Partner> {
    return this.post(
      `/lemur/api/v1/assistant/feedbacks?lang=${locale}`,
      feedback,
    )
  }

  // Creates a new email newsletter subscription
  createSubscription (
    locale: string,
    payload: {
      email: string,
    },
  ): Promise<SubscriptionResponse> {
    return this.post(
      `/lemur/api/v1/subscriptions?lang=${locale}`,
      payload,
    )
  }

  // Updates an existing email newsletter subscription
  updateSubscription (id: string, locale: string, data: Partial<{
    email: string,
    locale: string,
    firstName: string,
    lastName: string,
  }>): Promise<SubscriptionResponse> {
    const payload = pickBy({
      email: data.email,
      locale: data.locale,
      first_name: data.firstName,
      last_name: data.lastName,
    }, v => v !== undefined)

    return this.put(
      `/lemur/api/v1/subscriptions/${id}?lang=${locale}`,
      payload,
    )
  }
}
