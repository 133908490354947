
import { defineComponent } from 'vue'

import AssistantLayout from '~/components/AppAssistant/AssistantLayout.vue'
import AssistantInner from '~/components/AppAssistant/AssistantInner.vue'
import AssistantLoader from '~/components/AppAssistant/AssistantLoader.vue'
import AssistantOverlay from '~/components/AppAssistant/AssistantOverlay.vue'

export default defineComponent({
  name: 'AssistantFullscreen',
  components: {
    AssistantLayout,
    AssistantOverlay,
    AssistantLoader,
    AssistantInner,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
    isReady: {
      required: true,
      type: Boolean,
    },
    isError: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isShowLoader () {
      return !this.isReady && !this.isError
    },
  },
  watch: {
    isOpen (value) {
      if (value) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    },
  },
})
