
import { defineComponent } from 'vue'

import AppModal from '~/components/AppModal/AppModal.vue'
import AssistantFeedbackForm from '~/components/AppAssistant/feeback/AssistantFeedbackForm.vue'

export default defineComponent({
  name: 'AssistantFeedbackModal',
  components: {
    AppModal,
    AssistantFeedbackForm,
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  setup (_, { emit }) {
    const close = (delayMs = 0) => {
      setTimeout(() => {
        emit('close')
      }, delayMs)
    }

    const onSubmit = () => {
      close(3000)
    }

    const onError = () => {
      close(3000)
    }

    return {
      close,
      onSubmit,
      onError,
    }
  },
  computed: {
    feedbackEnvironment (): string {
      return this.$config.ASSISTANT_ENVIRONMENT as string
    },
  },
})
