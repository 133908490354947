
import { computed, defineComponent, getCurrentInstance, PropType, watch } from 'vue'

import { AssistantOfferContext } from '~/modules/assistant-v2'

import AssistantLayout from '~/components/AppAssistant/AssistantLayout.vue'
import AssistantInner from '~/components/AppAssistant/AssistantInner.vue'
import AssistantLoader from '~/components/AppAssistant/AssistantLoader.vue'
import AssistantError from '~/components/AppAssistant/AssistantError.vue'
import AssistantOverlay from '~/components/AppAssistant/AssistantOverlay.vue'
import AssistantCompactFooter from '~/components/AppAssistant/variants/AssistantCompact/AssistantCompactFooter.vue'
import AssistantOrderCTA from '~/components/AppAssistant/AssistantOrderCTA.vue'

import { GetterType as OrderGetterType, namespace as OrderModuleNamespace } from '~/store/modules/Amp/Order'

import { bodyScroll } from '~/utils/body-scroll.utils'

export default defineComponent({
  name: 'AssistantCompact',
  components: {
    AssistantOrderCTA,
    AssistantLayout,
    AssistantOverlay,
    AssistantLoader,
    AssistantError,
    AssistantInner,
    AssistantCompactFooter,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
    isReady: {
      required: true,
      type: Boolean,
    },
    isError: {
      required: true,
      type: Boolean,
    },
    offerContext: {
      type: Object as PropType<AssistantOfferContext>,
      default: null,
    },
  },

  setup (props) {
    const isMobileView = computed(() => process.client && window.innerWidth < 992)
    const isShowLoader = computed(() => !props.isReady && !props.isError)

    const app = getCurrentInstance()

    const orderGettersResolver = (getterType: string) => {
      return app?.proxy.$store.getters[`${OrderModuleNamespace}/${getterType}`]
    }

    const isDiscountCodeApplied = computed(() => orderGettersResolver(OrderGetterType.IS_DISCOUNT_CODE_APPLIED))
    const appliedDiscountCode = computed(() => orderGettersResolver(OrderGetterType.DISCOUNT_CODE))

    const isShowFooter = computed(() => {
      return props.isReady && !props.isError
    })

    watch(() => props.isOpen, (state) => {
      // Lock/unlock body scroll on mobile view only
      if (!isMobileView.value) {
        return
      }

      if (state) {
        bodyScroll.lock()
      } else {
        bodyScroll.unlock()
      }
    }, {
      immediate: true,
    })

    return {
      isMobileView,
      isShowFooter,
      isShowLoader,
      isDiscountCodeApplied,
      appliedDiscountCode,
    }
  },
})
