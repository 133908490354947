import { EventEmitter } from 'eventemitter3'

import { NamedOfferCategoryType } from '@alao-frontend/core'
import { getCookie, setCookie } from '@alao-frontend/utils'

import {
  AssistantEvents,
  AssistantOfferContext,
  AssistantState,
  BroadcastMessage,
  ModuleOptions,
} from '../assistant-v2'

// ----------------------------------------
// Constants
// ----------------------------------------
// Message types that can be sent to the assistant iframe
const SEND_MESSAGE_TYPES = {
  // Open/close the chat state
  // Atm we use the dialogEvent, which is not as clear naming
  STATE_CHANGE: 'stateChange',
  DIALOG_EVENT: 'dialogEvent',

  // Language change event
  LANG_CHANGE: 'langChange',

  // Send a message to the assistant
  // Not used atm
  PUSH_MESSAGE: 'pushMessage',

  // A different way to set offer context
  // Not used atm
  SET_OFFER_CONTEXT: 'setOfferContext',
}

// Message types that can be received from the assistant iframe
const RECEIVE_MESSAGE_TYPES = {
  // Open/close the chat state
  STATE_CHANGE: 'stateChange',
  DIALOG_EVENT: 'dialogEvent',

  // A click on a "tab" button with predefined prompt messages
  TAB_CLICK: 'tabClick',

  // A manually free written message from the user
  MESSAGE_SEND: 'messageSend',

  // A chat ID assigned to the user session
  CHAT_ID_UPDATE: 'chatId',

  // Focus/blur events on the message input
  MESSAGE_INPUT_FOCUS: 'messageInputFocus',
  MESSAGE_INPUT_BLUR: 'messageInputBlur',
}

// ----------------------------------------
// Utility functions
// ----------------------------------------
const formatIframeSrc = (rawUrl: string, searchParams: Record<string, string>) => {
  const url = new URL('/', rawUrl)

  for (const [key, value] of Object.entries(searchParams)) {
    url.searchParams.set(key, value)
  }

  return url.toString()
}

// FIFO queue for messages
export class Queue<T extends Record<string, any>> {
  private queue: T[] = []

  push (payload: T) {
    this.queue.push(payload)
  }

  pop () {
    return this.queue.pop()
  }

  get length () {
    return this.queue.length
  }

  flush () {
    const queue = this.queue
    this.queue = []
    return queue
  }
}

// TODO: Move into separate library
const createLogger = (prefixBase: string, debug: boolean): Console => {
  const methods: (keyof Console)[] = ['log', 'info', 'warn', 'error', 'debug']

  const styles = 'color: white;font-weight: bold;background: #4574ed;padding: 2px;border-radius: 3px'
  const formatPrefix = (type: string) => `%c[${prefixBase}:${type}]`

  return new Proxy<Console>(console, {
    get (target, prop: keyof Console) {
      if (methods.includes(prop)) {
        // Disable debug logs if debug is set to false
        if (prop === 'debug' && !debug) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          return () => {}
        }

        return (...args: any[]) => {
          (target[prop] as (...args: any[]) => void)(formatPrefix(prop), styles, ...args)
        }
      }
      return target[prop]
    },
  })
}

// Simple store implementation to manage the assistant state separately
class SimpleStore<State extends Record<string, any>> {
  readonly #state: State = {} as State
  readonly #defaultState: State = {} as State

  protected constructor (defaultState: State) {
    this.#defaultState = defaultState
    this.#state = Object.assign({}, defaultState)
  }

  static create<State extends Record<string, any>>(defaultState: State) {
    return new SimpleStore<State>(defaultState)
  }

  get <Prop extends keyof State, Value extends State[Prop]> (prop: Prop): Value {
    return this.#state[prop]
  }

  set<
    Prop extends keyof State,
    Value extends State[Prop],
  >(prop: Prop, value: Value) {
    this.#state[prop] = value
  }

  get state (): Readonly<State> {
    return this.#state
  }

  reset () {
    Object.assign(this.#state, this.#defaultState)
  }
}

export class AssistantContext {
  private readonly logger!: Console
  private readonly store!: ReturnType<typeof SimpleStore.create<AssistantState>>
  private readonly queue = new Queue<BroadcastMessage>()
  private readonly eventEmitter!: EventEmitter<AssistantEvents>

  constructor (private readonly moduleOptions: ModuleOptions) {
    this.logger = createLogger('assistant', moduleOptions.debug)

    this.eventEmitter = new EventEmitter<AssistantEvents>()

    this.store = SimpleStore.create<AssistantState>({
      locale: moduleOptions.fallbackLocale,
      namespace: moduleOptions.postMessageNamespace,
      theme: moduleOptions.theme,
      open: false,
      initialized: false,
      loaded: false,
      iframeElement: null,
      containerElement: null,
      offerContext: null,
      sentMessagesCounter: 0,
      chatId: '',
    })

    this.logger.debug('Creating assistant context with default state:', this.store)
  }

  get isInitialized () {
    return this.store.get('initialized')
  }

  get iframeUrl () {
    const { offerId, offerType } = this.store.get('offerContext') || {}

    if (!offerId || !offerType) {
      throw new Error('Offer context is required to initialize the assistant.')
    }

    return formatIframeSrc(
      this.moduleOptions.iframeOrigin,
      {
        no_drawer: 'true',
        theme: this.store.get('theme'),
        locale: this.store.get('locale'),
        namespace: this.moduleOptions.postMessageNamespace,
        view: this.deviceType,
        offer_id: offerId,
        offer_type: NamedOfferCategoryType[offerType],
      },
    )
  }

  get deviceType (): 'mobile' | 'desktop' {
    // Fallback to desktop if current context is server
    if (process.server) {
      return 'desktop'
    }

    // Otherwise, handle it normally
    return window.innerWidth < 992 ? 'mobile' : 'desktop'
  }

  sendMessage (message: string) {
    this.broadcastMessage(SEND_MESSAGE_TYPES.PUSH_MESSAGE, {
      value: message,
    })

    this.emit('message', message)
  }

  mount (containerElementOrHandle: HTMLElement | string, locale: string) {
    if (this.moduleOptions.disabled) {
      this.logger.warn('The module is disabled. Skipping initialization.')
      return this
    }

    if (!containerElementOrHandle) {
      throw new Error('Container required to initialize assistant. Please provide a valid container element or handle.')
    }

    this.store.set('containerElement', this.resolveContainerElement(containerElementOrHandle))
    this.store.set('locale', locale)

    window.addEventListener('message', this.boundHandleMessage)

    return this
  }

  initialize (offerContext: AssistantOfferContext) {
    if (this.moduleOptions.disabled) {
      this.logger.warn('The module is disabled. Skipping initialization.')
      return this
    }

    if (!this.store.get('containerElement')) {
      this.logger.error('Container element is not initialized. Please attach the assistant to a container first.')
      return this
    }

    // Check whether the offer context already exists in the store
    // and the new offer context is different to avoid unnecessary updates
    if (this.store.get('offerContext')) {
      const currentOfferContext = this.store.get('offerContext') as AssistantOfferContext

      if (currentOfferContext.offerId === offerContext.offerId) {
        this.logger.debug('Offer context is already set. Skipping reinitialization.')
        return this
      }
    }

    // Make sure we have a valid offer context before initializing
    this.setOfferContext(offerContext)

    const iframeElement = this.getIframeElement()
    iframeElement.src = this.iframeUrl

    this.store.set('iframeElement', iframeElement)
    // Reset sent messages counter
    this.store.set('sentMessagesCounter', 0)

    if (this.store.get('initialized')) {
      return this
    }

    iframeElement.addEventListener('load', this.onIframeLoad.bind(this))

    this.store.set('initialized', true)

    this.logger.debug('Assistant context has been initialized.', this.store)

    return this
  }

  getIframeElement (): HTMLIFrameElement {
    if (this.store.get('iframeElement')) {
      return this.store.get('iframeElement') as HTMLIFrameElement
    }

    const iframeElement = document.createElement('iframe')
    // Set the iframe styles
    iframeElement.style.width = '100%'
    iframeElement.style.height = '100%'
    iframeElement.style.border = 'none'
    iframeElement.style.display = 'block' // Prevent overflow issues

    const iframeAttributes: Record<string, string> = {
      width: '100%',
      height: '100%',
      title: 'Alao Assistant',
      name: 'alao-assistant',
      loading: 'eager',
      // allow: 'camera', // Uncomment this line to enable camera access, if needed
    }

    Object.keys(iframeAttributes).forEach((key) => {
      iframeElement.setAttribute(key, iframeAttributes[key])
    })

    // Fall back to text content if iframe is not supported
    iframeElement.textContent = 'Your browser does not support iframes.'

    this.logger.debug('Created iframe element', {
      url: this.iframeUrl,
      attrs: iframeAttributes,
    })

    return iframeElement
  }

  destroy () {
    window.removeEventListener('message', this.boundHandleMessage)

    const iframeElement = this.store.get('iframeElement') as HTMLIFrameElement

    if (iframeElement) {
      iframeElement.removeEventListener('load', this.onIframeLoad.bind(this))
      iframeElement.remove()
    }

    this.store.reset()
    this.queue.flush()

    this.emit('destroy')
    this.removeAllListeners()

    return this
  }

  private setOfferContext (offerContext: AssistantOfferContext) {
    this.store.set('offerContext', offerContext)

    this.broadcastMessage(SEND_MESSAGE_TYPES.SET_OFFER_CONTEXT, offerContext)

    this.emit('offerContextUpdate', offerContext)

    return this
  }

  setLocale (locale: string) {
    // Prevent setting the same locale
    if (locale === this.store.get('locale')) {
      return
    }

    this.logger.debug('Setting locale to:', locale)

    this.store.set('locale', locale)

    this.broadcastMessage(SEND_MESSAGE_TYPES.LANG_CHANGE, {
      lang: locale,
    })

    this.emit('localeChange', locale)
  }

  on (...args: Parameters<typeof this.eventEmitter.on>) {
    this.eventEmitter.on(...args)

    return this
  }

  off (...args: Parameters<typeof this.eventEmitter.off>) {
    this.eventEmitter.off(...args)

    return this
  }

  removeAllListeners (...args: Parameters<typeof this.eventEmitter.removeAllListeners>) {
    this.eventEmitter.removeAllListeners(...args)

    return this
  }

  emit (...args: Parameters<typeof this.eventEmitter.emit>) {
    this.eventEmitter.emit(...args)

    return this
  }

  open () {
    if (!this.store.get('open')) {
      const payload = {
        value: true,
      }

      this.broadcastMessage(SEND_MESSAGE_TYPES.STATE_CHANGE, payload)
      this.broadcastMessage(SEND_MESSAGE_TYPES.DIALOG_EVENT, payload)
    }

    this.handleOpen()

    return this
  }

  close () {
    if (this.store.get('open')) {
      const payload = {
        value: false,
      }
      this.broadcastMessage(SEND_MESSAGE_TYPES.STATE_CHANGE, payload)
      this.broadcastMessage(SEND_MESSAGE_TYPES.DIALOG_EVENT, payload)
    }

    this.handleClose()

    return this
  }

  private maybeRequestFeedback () {
    // Allow only one feedback request per session
    if (getCookie('alao_feedback_requested')) {
      return
    }

    const { sentMessagesCounter } = this.store.state

    if (sentMessagesCounter >= this.moduleOptions.displayFeedbackMessagesThreshold) {
      setCookie('alao_feedback_requested', '1', {
        expires: undefined, // Session cookie
        path: '/',
      })
      this.emit('requestFeedback', this.store.get('chatId'))
    }
  }

  private handleOpen () {
    this.store.set('open', true)

    this.emit('open')

    if (!this.store.get('loaded')) {
      this.insertIframe()
    }
  }

  private handleClose () {
    this.store.set('open', false)

    this.emit('close')
    this.maybeRequestFeedback()
  }

  private insertIframe () {
    try {
      const iframeElement = this.store.get('iframeElement')
      const containerElement = this.store.get('containerElement')

      if (!iframeElement) {
        throw new Error('Iframe element is not initialized')
      }

      if (!containerElement) {
        throw new Error('Container element is not initialized')
      }

      this.logger.debug('Inserting the widget into container:', containerElement)

      containerElement.prepend(iframeElement)
    } catch (error) {
      this.logger.error('Error inserting iframe:', error)
      this.emit('error', error)
    }
  }

  private broadcastMessage <
    MessageType extends string = string,
    MessagePayload extends Record<string, unknown> = Record<string, unknown>,
  > (type: MessageType, payload: MessagePayload, namespace?: string) {
    const messagePayload: BroadcastMessage = {
      namespace: namespace || this.moduleOptions.postMessageNamespace,
      type,
      payload,
    }

    // If the iframe is not loaded yet, push the message to the queue
    // to be sent once the iframe is loaded
    if (!this.store.get('loaded')) {
      this.queue.push(messagePayload)

      return
    }

    const iframeElement = this.store.get('iframeElement')

    if (!iframeElement) {
      return
    }

    this.logger.debug(`Broadcasting message of type "${type}"`, messagePayload)

    iframeElement.contentWindow?.postMessage(
      // Temp reassign to make the iframe accept the message properly
      {
        namespace: messagePayload.namespace,
        type: messagePayload.type,
        value: messagePayload.payload,
      },
      '*',
    )
  }

  private boundHandleMessage = this.handleMessage.bind(this)

  // TODO: Map the event data to the correct type
  private handleMessage<
    EventData extends Record<string, any> = Record<string, unknown>,
  >(event: MessageEvent<EventData>) {
    const { namespace, type, value } = event.data

    // Uncomment when implemented on the iframe side
    if (namespace !== this.moduleOptions.postMessageNamespace) {
      return
    }

    this.logger.debug(`Received message of type "${type}"`, value)

    // Handle the message based on the type RECEIVE_MESSAGE_TYPES
    switch (type) {
      case RECEIVE_MESSAGE_TYPES.DIALOG_EVENT:
      case RECEIVE_MESSAGE_TYPES.STATE_CHANGE:
        // Handle only close event
        if (!value) {
          this.handleClose()
        }
        break

      case RECEIVE_MESSAGE_TYPES.TAB_CLICK:
        this.onTabClick(value)
        break

      case RECEIVE_MESSAGE_TYPES.MESSAGE_SEND:
        this.onMessage(value)
        break

      case RECEIVE_MESSAGE_TYPES.CHAT_ID_UPDATE:
        this.store.set('chatId', value)
        break

      case RECEIVE_MESSAGE_TYPES.MESSAGE_INPUT_FOCUS:
        this.emit('input:focus')
        break

      case RECEIVE_MESSAGE_TYPES.MESSAGE_INPUT_BLUR:
        this.emit('input:blur')
        break

      default:
        break
    }
  }

  private onTabClick (value: string) {
    this.emit('tabClick', value)
    this.increaseSentMessagesCounter()
  }

  private onMessage (value: string) {
    this.emit('message', value)
    this.increaseSentMessagesCounter()
  }

  private increaseSentMessagesCounter () {
    let counter = this.store.get('sentMessagesCounter')
    this.store.set('sentMessagesCounter', ++counter)
  }

  private resolveContainerElement (containerElementOrHandle: HTMLElement | string) {
    let containerElement: HTMLElement | null = null

    switch (typeof containerElementOrHandle) {
      case 'string':
        containerElement = document.querySelector(containerElementOrHandle)
        break
      default:
        containerElement = containerElementOrHandle
    }

    if (!containerElement) {
      throw new Error('Container element not found. Please provide a valid container element.')
    }

    return containerElement
  }

  private onIframeLoad () {
    this.store.set('loaded', true)

    this.logger.debug('Chat iframe has been loaded.')

    // Delay the ready event to avoid the loader flicker effect
    setTimeout(() => {
      this.emit('ready')

      while (this.queue.length) {
        const message = this.queue.pop() as BroadcastMessage
        this.broadcastMessage(message.type, message.payload, message.namespace)
      }
    }, 300)
  }
}
