
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import MobileMenu from '~/components/AppHeader/MobileMenu.vue'
import { OnBeforeDestroy, OnMounted } from '~/core/vue.types'
import AppHeaderContact from '~/components/AppHeader/AppHeaderContact.vue'
import { PhoneNumberType } from '~/mixins/contact.mixin'
import AppHeaderDropdownMenu from '~/components/AppHeader/AppHeaderDropdownMenu/AppHeaderDropdownMenu.vue'
import AppLangSwitcher from '~/components/snippets/AppLangSwitcher.vue'
import { DropdownMenuItem } from '~/components/AppHeader/AppHeaderDropdownMenu/types'
import { FeatureFlags } from '~/core/config/feature-flags'
import GoogleRating from '~/components/widgets/GoogleRating.vue'
import GoogleRatingLite from '~/components/widgets/GoogleRatingLite.vue'
import { SiteNavigationMixin } from '~/mixins/site-navigation.mixin'
import type { Theme } from '~/types/theme'

@Component({
  components: {
    GoogleRatingLite,
    GoogleRating,
    MobileMenu,
    AppLangSwitcher,
    AppHeaderDropdownMenu,
    AppHeaderContact,
  },
})
export default class AppHeader extends SiteNavigationMixin implements OnMounted, OnBeforeDestroy {
  @Prop({
    required: false,
    default: 'default',
  })
  readonly type!: 'default' | 'mobile-like' | 'navigation-less'

  @Prop({
    required: true,
    default: true,
  })
  readonly isShadow!: boolean

  @Prop({
    required: false,
    default: 'left',
  })
  readonly logoPosition!: 'left' | 'center'

  @Prop({
    type: String,
    required: false,
    default: 'light',
  })
  readonly theme!: Theme

  @Prop({
    required: false,
    default: false,
  })
  readonly showMobileSwitcher!: boolean

  private isMobileNavActive = false
  private isShadowAppliedByScroll = false
  private readonly PHONE_NUMBER_TYPE = Object.freeze(PhoneNumberType)

  // START TODO: move this to layout
  private isShowMobileFilterButton = false
  private currentScrollTop = 0
  private startScrollAppearanceOnMobile = 130
  private startScrollAppearanceOnDesktop = 430
  private availableRouteList = [this.localePath('compare-bundle'), this.localePath('compare-home'), this.localePath('compare-mobile')]

  get logoImage () {
    return this.theme === 'light'
      ? require('~/assets/images/alao-logo.svg')
      : require('~/assets/images/icons/alao-logo-white-orange.svg')
  }

  get aboutMenuItems (): DropdownMenuItem[] {
    return [
      {
        title: this.$t('header.about.ourStory') as string,
        link: this.localePath('about-us'),
        dataE2e: 'headerOurStoryLink',
      },
      {
        title: this.$t('header.about.howItWorks') as string,
        link: this.localePath('how-it-works'),
        dataE2e: 'headerHowItWorksLink',
      },
      {
        title: this.$t('header.about.coverage') as string,
        link: this.localePath('coverage'),
        dataE2e: 'headerCoverageLink',
      },
    ]
  }

  private saveCurrentScrollPage () {
    if (this.activatePanel) {
      this.currentScrollTop = window.pageYOffset
    }
  }

  get activatePanel (): boolean {
    return this.$mqUtils.isMobile || this.$mqUtils.isMD || this.$mqUtils.isLG
  }

  get startPosition (): number {
    if (this.$mqUtils.isMobile) {
      return this.startScrollAppearanceOnMobile
    } else {
      return this.startScrollAppearanceOnDesktop
    }
  }

  get isActivateScrollLogics () {
    return this.availableRouteList.includes(this.$route.path)
  }

  @Watch('currentScrollTop')
  onChangeCurrentScrollTop (currentValue: number, prevValue: number) {
    this.isShowMobileFilterButton = currentValue < prevValue
  }

  get showB2B () {
    return this.$featureFlags.isEnabled(FeatureFlags.B2B_PAGE) && this.$i18n.locale === 'de'
  }

  get showContactButton (): boolean {
    const availableRouteList = [
      this.localePath('offer'),
      this.localePath({
        name: 'compare-bundle-slug',
      }),
      this.localePath({
        name: 'compare-home-slug',
      }),
      this.localePath({
        name: 'compare-mobile-slug',
      }),
    ]

    return availableRouteList.includes(this.$route.path) &&
      [this.$mqUtils.isMobile, this.$mqUtils.isMD].includes(true)
  }

  toggleMobileNav () {
    this.isMobileNavActive = !this.isMobileNavActive
  }

  onScrollHandler () {
    this.isShadowAppliedByScroll = window.scrollY > 50
  }

  navigateTo (localizedPath: string) {
    this.$router.push(localizedPath)
  }

  mounted () {
    window.addEventListener('scroll', this.onScrollHandler.bind(this), { passive: true })
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this))

    // TODO: move this to layout
    document.removeEventListener('scroll', this.saveCurrentScrollPage)
  }

  // TODO: move this to layout
  @Watch('$route.path', { immediate: true })
  handlerRouteChange (currentRoute: string, prevRoute: string) {
    if (process.client) {
      if (this.availableRouteList.includes(prevRoute)) {
        document.removeEventListener('scroll', this.saveCurrentScrollPage, true)
      }

      if (this.availableRouteList.includes(currentRoute)) {
        document.addEventListener('scroll', this.saveCurrentScrollPage, true)
      }
    }
  }
}
