
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WhatsAppWidget',
  props: {
    contactNumber: {
      type: String,
      required: true,
    },
  },
})

